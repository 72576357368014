import React from 'react';

import { Link } from 'react-router-dom';

import styles from './DevideBlock.module.scss';

import Button from 'shared/components/Button/Button';
import { dataBase } from 'shared/data/data';
import { disableCopy } from 'shared/model/disableCopy';

interface IDevideBlock {
  text: string;
  btnText: string;
  btnFn(): void;
  sign?: boolean;
  consult?: boolean;
  btnLink: string;
}

const data = dataBase.contacts.links[0];

export const DevideBlock: React.FC<IDevideBlock> = ({ text, btnText, btnFn, sign, consult, btnLink }: IDevideBlock) => {
  return (
    <div onMouseDown={disableCopy} className={styles.signBlock}>
      <p className={styles.signBlock__text}>{text}</p>
      {consult ? (
        <Link
          className={consult ? 'devide-link btn-link__consult' : 'devide-link btn-link'}
          to={data.link}
          target='_blank'
        >
          <Button
            sign={sign}
            consult={consult}
            text={btnText}
            onClick={() => {}}
            additionalClass={consult ? `${styles.singBlock__btn} btn-consult` : styles.singBlock__btn}
          />
        </Link>
      ) : (
        <Button
          sign={sign}
          consult={consult}
          text={btnText}
          onClick={btnFn}
          additionalClass={consult ? `${styles.singBlock__btn} btn-consult` : styles.singBlock__btn}
        />
      )}
    </div>
  );
};
