import React from 'react';

import { ServicesList } from 'widgets';

export const ServiceList: React.FC = () => {
  return (
    <>
      <h1 className='visually-hidden'>Классификация услуг</h1>
      <ServicesList global={true} apparat={false} inject={false} other={false} />
    </>
  );
};
