import React, { useState } from 'react';

import styles from './FaqBlock.module.scss';

import Toggler from 'shared/Icons/Toggler';
import { disableCopy } from 'shared/model/disableCopy';
import { IFAQ } from 'shared/types/types';

interface IOpenAnswer extends IFAQ {
  additionalClass: string;
}

export const FaqBlock: React.FC<IOpenAnswer> = ({ id, question, answer, additionalClass }: IOpenAnswer) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleFaq = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <div onMouseDown={disableCopy} id={String(id)} className={styles.faq}>
        <p onClick={toggleFaq} className={styles.faq__question}>
          <span>{question}</span>
          <button className={styles.togglerBtn}>
            <Toggler
              className={
                isOpen
                  ? `${styles.toggler} ${styles.rotateToggler} ${additionalClass}`
                  : `${styles.toggler} ${styles.standartToggler} ${additionalClass}`
              }
            />
          </button>
        </p>
        <p className={isOpen ? `${styles.faq__answer} ${styles.faq__answer_open}` : styles.faq__answer}>{answer}</p>
      </div>
    </>
  );
};
