import React from 'react';

import styles from './CriolipolisPrice.module.scss';

import { dataBase } from 'shared/data/data';

const data = dataBase.pricesCrio;

export const CriolipolisPrice: React.FC = () => {
  return (
    <div className={styles.crioPrice}>
      {data.map((crio) => (
        <div className={styles.crioPrice__wrapper} key={crio.serviceName}>
          <h2 className={styles.crioPrice__header} key={crio.serviceName}>
            {crio.serviceName}
          </h2>
          <div className={styles.crioTable__wrapper}>
            {crio.params.map((params) => (
              <table className={styles.crioTable} key={params.head}>
                <thead className={styles.crioPrice__head} key={params.head}>
                  <tr className={styles.crioPrice__str} key={params.head}>
                    <th className={styles.crioTable__header}>{params.head}</th>
                    <th className={styles.crioTable__emptyCol}></th>
                  </tr>
                </thead>
                <tbody className={styles.crioPrice__body}>
                  {params.params.map((param) => (
                    <tr className={styles.crioPrice__str} key={param.time}>
                      <th className={styles.crioTable__time}>{param.time}</th>
                      <td className={styles.crioPrice__td}>{param.price}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
