import React from 'react';

import styles from './PricePage.module.scss';

import { PriceList } from 'widgets';

export const PricePage: React.FC = () => {
  return (
    <div className={styles.pricePage}>
      <h1 className='visually-hidden'>Прайс</h1>
      <PriceList />
    </div>
  );
};
