import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import styles from './ServicesList.module.scss';

import { getPathName } from '../model/getPathname';
import { IServicesType } from '../types/types';

import { ServicesListCard } from 'entites';

import { RouterLinks } from 'shared/constants/routes';
import { dataBase } from 'shared/data/data';
import { scrollUp } from 'shared/model/scrollUp';
import { IServices } from 'shared/types/types';

let data: IServices[];

export const ServicesListItems: React.FC<IServicesType> = ({ apparat, global, inject, other }: IServicesType) => {
  const route = RouterLinks.services;
  const globalRoutes = [RouterLinks.apparatServices, RouterLinks.otherServices, RouterLinks.injestServices];

  const path = useLocation().pathname;

  const pathname = getPathName(path, globalRoutes);

  if (apparat) {
    data = dataBase.services.apparat;
  } else if (inject) {
    data = dataBase.services.inject;
  } else if (other) {
    data = dataBase.services.other;
  } else {
    data = dataBase.services.global;
  }
  return (
    <>
      {data.map((service, i) => (
        <li onClick={scrollUp} key={service.id} className={styles.servicesList__item}>
          <Link to={global ? `${route}${globalRoutes[i]}` : `${route}${pathname}/${service.id}`}>
            <ServicesListCard
              key={service.id}
              id={service.id}
              name={service.name}
              description={service.description}
              image={service.image}
              additionalClass={global ? `${styles.globalCard}` : ''}
            />
          </Link>
        </li>
      ))}
    </>
  );
};
