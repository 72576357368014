import React from 'react';

import { ServicesList } from 'widgets';

export const OtherServiceList: React.FC = () => {
  return (
    <>
      <h1 className='visually-hidden'>Прочие услуги</h1>
      <ServicesList global={false} apparat={false} inject={false} other={true} />
    </>
  );
};
