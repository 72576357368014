import React, { useEffect } from 'react';

import AppRouter from '../../router/AppRouter';

import { ConsultBtn } from 'entites';
import { getSign } from 'shared/api/getSign';
import { Footer, Header, StockWindow } from 'widgets';

export const App: React.FC = () => {
  useEffect(() => {
    getSign();
  }, []);

  return (
    <>
      <Header />
      <main>
        <AppRouter />
        <ConsultBtn />
        <StockWindow />
      </main>
      <Footer />
    </>
  );
};
