import React from 'react';

import styles from './Advantages.module.scss';

import AdvantagesCard from './components/AdvantagesCard';

import { dataBase } from 'shared/data/data';
import { disableCopy } from 'shared/model/disableCopy';

const data = dataBase.aboutUs;

export const Advantages: React.FC = () => {
  return (
    <>
      <h2 className={styles.advantages__header}>{data.subheader}</h2>
      <div onMouseDown={disableCopy} className={styles.advantages}>
        {data.advantages.map((advantage) => (
          <AdvantagesCard key={advantage.id} advantages={advantage} />
        ))}
      </div>
    </>
  );
};
