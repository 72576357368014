import { dataBase } from 'shared/data/data';

export const singBtn = (): void => {
  window.arnicaBooking.open();
};

export const detailsBtn = (): void => {
  alert('Подробнее');
};

export const consultBtn = (): void => {
  const data = dataBase.contacts.links[0];
  // const stateObj = {};
  window.history.replaceState({}, '', data.link);
};
