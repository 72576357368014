export const getPathName = (location: string, names: string[]): string => {
  const resultItems = names.map((item) => {
    return location.includes(item) ? item : '';
  });

  const result = resultItems.filter((item) => {
    return item.length > 0;
  });

  return result[0];
};
