import React from 'react';

import { Specialists } from 'widgets';

export const SpecialistsPage: React.FC = () => {
  return (
    <>
      <h1 className='visually-hidden'>Наши специалисты</h1>
      <Specialists />
    </>
  );
};
