import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Scroll from 'react-scroll';

import { navbarList } from './constants/consts';
import styles from './Navbar.module.scss';

interface INavbar {
  closeNav(): void;
}

export const Navbar: React.FC<INavbar> = ({ closeNav }: INavbar) => {
  const AncLink = Scroll.Link;
  const navigation = useNavigate();

  const redirect = (): void => {
    navigation('/');
  };
  return (
    <div className={styles.navigation}>
      <ul className={styles.navigationList}>
        {navbarList.map((item, i) => (
          <li onClick={closeNav} className={styles.navigationList__item} key={item.name}>
            {i < 3 ? (
              <Link to={item.link}>{item.name}</Link>
            ) : (
              <AncLink onMouseDown={redirect} onClick={closeNav} to={item.link}>
                {item.name}
              </AncLink>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
