import React from 'react';
import { Link } from 'react-router-dom';

import { ServiceConstants } from './constants/constants';
import styles from './ServiceCard.module.scss';

import Button from 'shared/components/Button/Button';
import { BtnContants } from 'shared/constants/constants';
import { RouterLinks } from 'shared/constants/routes';
import { dataBase } from 'shared/data/data';
import { singBtn } from 'shared/model/MainFunctionBtns';

import { scrollUp } from 'shared/model/scrollUp';
import { IService } from 'shared/types/types';

const data = dataBase.contacts.links[0];

export const ServiceCard: React.FC<IService> = ({
  name,
  image,
  fullDescription,
  indications,
  contraindications,
  preparing,
  price,
  icon,
}: IService) => {
  return (
    <div className={styles.serviceCard}>
      <h2 className={styles.serviceCard__header}>
        <span>{name}</span>
        <img className={styles.serviceCard__icon} src={icon} alt={name} />
      </h2>
      <div className={styles.serviceCard__mainContentWrapper}>
        <div className={styles.serviceCard__mainContent}>
          <div className={styles.serviceCard__imgWrapper}>
            <img className={styles.serviceCard__img} src={image} alt={name} />
          </div>
          <p className={styles.serviceCard__text}>{fullDescription}</p>
        </div>
        <div className={styles.serviceCard__content}>
          <ul className={styles.serviceCardList}>
            <h3 className={styles.serviceCardList__header}>{ServiceConstants.indications}</h3>
            {indications.map((listItem) => (
              <li className={styles.serviceCardList__item} key={listItem}>
                {listItem}
              </li>
            ))}
          </ul>
          <ul className={styles.serviceCardList}>
            <h3 className={styles.serviceCardList__header}>{ServiceConstants.contraindications}</h3>
            {contraindications.map((listItem) => (
              <li className={styles.serviceCardList__item} key={listItem}>
                {listItem}
              </li>
            ))}
          </ul>
          <div>
            <h3 className={styles.serviceCardList__header}>{ServiceConstants.preparing}</h3>
            <p className={styles.serviceCard__text}>{preparing}</p>
          </div>
        </div>
        <div className={styles.serviceCard__footer}>
          <div className={styles.serviceCard__priceWrapper}>
            <h3 className={styles.serviceCard__price}>{`${ServiceConstants.startPrice} ${price}`}</h3>
            <Link onClick={scrollUp} className={styles.serviceCard__priceLink} to={RouterLinks.price}>
              {ServiceConstants.price}
            </Link>
          </div>
          <div className={styles.serviceCard__btnWrapper}>
            <Link target='_blank' to={data.link}>
              <Button
                consult={true}
                onClick={() => console.log()}
                text={BtnContants.consultation.name}
                additionalClass={`${styles.serviceCard__btn} btn-consult`}
              />
            </Link>
            <Button
              sign={true}
              text={BtnContants.sign.name}
              onClick={singBtn}
              additionalClass={styles.serviceCard__btn}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
