export enum RouterLinks {
  main = '/',
  about = '#about',
  services = '/service-list',
  injestServices = '/injectable-services',
  apparatServices = '/hardware-services',
  otherServices = '/other-services',
  price = '/prices',
  specialists = '/specialists',
  contacts = '#contacts',
  faq = '#faq',
  politic = '/confidential-politic',
}
