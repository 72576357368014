import React, { useState, useEffect } from 'react';

import styles from './BurgerBtn.module.scss';

interface IBurgerBtn {
  onClick(): void;
  openNav: boolean;
}

export const BurgerBtn: React.FC<IBurgerBtn> = ({ onClick, openNav }: IBurgerBtn) => {
  const [open, setOpen] = useState<boolean>(openNav);
  const openNavBtn = (): void => {
    onClick();
  };

  useEffect(() => {
    setOpen(openNav);
  }, [openNav]);

  return (
    <div onClick={openNavBtn} className={styles.burgerBtn__wrapper}>
      <button className={open ? `${styles.burgerBtn} ${styles.burgerBtn_open}` : styles.burgerBtn}></button>
    </div>
  );
};
