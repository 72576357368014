import React from 'react';

import styles from './Stocks.module.scss';

import { SliderStocks } from 'features';

export const Stocks: React.FC = () => {
  return (
    <section className={`${styles.sliderStocks__section}`}>
      <SliderStocks />
    </section>
  );
};
