import React from 'react';

import { InvalidRouteInfo } from 'entites';

export const ErrorPage: React.FC = () => {
  return (
    <>
      <h1 className='visually-hidden'>Ошибка</h1>
      <InvalidRouteInfo />
    </>
  );
};
