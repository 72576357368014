import React from 'react';

import { ServicePrice } from './components/ServicePrice';

export const ServicesPrice: React.FC = () => {
  return (
    <>
      <ServicePrice />
    </>
  );
};
