import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Logo.module.scss';

import { RouterLinks } from 'shared/constants/routes';

const Logo: React.FC = () => {
  return (
    <Link className={styles.logo__wrapper} to={`${RouterLinks.main}`}>
      <svg
        className={styles.logo}
        version='1.0'
        xmlns='http://www.w3.org/2000/svg'
        width='900.000000pt'
        height='900.000000pt'
        viewBox='0 0 900.000000 900.000000'
        preserveAspectRatio='xMidYMid meet'
        fill='#000000'
      >
        <g transform='translate(0.000000,900.000000) scale(0.100000,-0.100000)' stroke='none'>
          <path
            d='M5025 8281 c-25 -11 -60 -49 -52 -57 3 -3 19 3 34 14 61 43 150 40
174 -6 8 -15 17 -29 21 -32 4 -3 12 -44 18 -92 13 -98 1 -219 -25 -245 -8 -8
-15 -19 -15 -24 0 -16 -54 -79 -67 -79 -7 0 -13 -4 -13 -10 0 -16 -42 -11 -55
7 -18 25 -35 7 -28 -30 3 -18 9 -34 13 -37 8 -6 40 -203 40 -245 0 -45 -29
-174 -47 -209 -20 -39 -89 -123 -128 -156 -16 -14 -36 -32 -45 -40 -8 -8 -58
-51 -110 -94 -52 -44 -114 -97 -137 -118 -24 -21 -63 -48 -87 -60 -41 -19 -48
-20 -92 -8 -75 21 -195 145 -156 162 4 2 17 18 30 36 l24 33 -27 25 c-18 17
-38 24 -66 24 -21 0 -39 -4 -39 -10 0 -5 -6 -10 -14 -10 -22 0 -71 -56 -100
-114 -15 -30 -40 -65 -56 -79 -55 -49 -190 -77 -190 -39 1 24 34 95 53 110 9
7 30 40 47 74 17 33 47 73 66 90 19 17 34 34 34 39 0 12 -37 -7 -64 -32 -11
-11 -23 -19 -28 -19 -4 0 -21 -28 -37 -63 -15 -35 -41 -75 -56 -90 -23 -20
-29 -36 -33 -81 -7 -85 -7 -86 83 -70 76 13 125 36 159 73 20 22 64 97 72 123
4 10 16 20 27 24 25 8 69 -40 59 -65 -7 -21 -65 -105 -74 -109 -5 -2 -8 -8 -8
-14 0 -16 -98 -137 -165 -203 -55 -55 -145 -125 -159 -125 -3 0 -15 -8 -25
-17 -11 -10 -33 -29 -50 -43 -80 -63 -102 -125 -155 -425 -9 -49 -24 -135 -33
-190 -22 -126 -25 -509 -5 -595 33 -140 43 -224 30 -250 -7 -14 -15 -27 -18
-30 -3 -3 -30 -39 -60 -80 -29 -41 -82 -111 -117 -155 -35 -44 -74 -93 -86
-109 -12 -15 -27 -33 -33 -40 -48 -53 -164 -246 -164 -275 0 -6 -4 -11 -8 -11
-5 0 -17 -35 -26 -77 -10 -43 -23 -94 -28 -114 -14 -51 16 -206 55 -278 25
-46 93 -123 138 -156 54 -39 124 -46 157 -15 55 52 95 181 141 455 10 61 24
137 30 170 51 272 71 445 70 605 l-1 125 62 110 c33 61 65 117 70 125 5 8 21
40 36 70 222 448 258 518 334 650 37 64 121 195 137 215 4 6 17 24 27 40 65
101 266 304 364 368 117 76 205 108 342 128 62 8 183 0 223 -16 12 -5 17 -2
17 8 0 46 -342 43 -405 -3 -5 -4 -25 -11 -42 -15 -18 -3 -33 -10 -33 -13 0 -4
-15 -13 -32 -21 -124 -53 -357 -268 -468 -430 -13 -20 -28 -40 -33 -46 -28
-33 -120 -184 -183 -300 -48 -90 -249 -489 -264 -523 -6 -15 -22 -47 -36 -72
-14 -25 -44 -79 -66 -120 -22 -41 -44 -79 -49 -84 -11 -12 -12 -8 -40 139 -19
101 -23 150 -22 315 1 135 6 224 18 290 9 52 23 131 30 175 55 341 83 399 238
502 136 92 254 216 354 373 33 52 63 77 63 52 0 -16 86 -95 124 -113 50 -24
117 -24 155 -1 28 17 86 64 154 125 18 16 50 43 71 60 111 90 230 204 253 242
22 36 53 106 53 120 0 8 5 17 10 20 19 12 11 243 -10 320 -25 89 -25 85 11 85
45 0 122 82 144 153 9 29 20 60 26 69 12 23 12 140 0 170 -5 13 -15 45 -22 72
-7 26 -23 61 -37 77 -22 26 -29 29 -85 28 -34 0 -71 -4 -82 -8z m-750 -1282
c4 -5 0 -18 -9 -28 -9 -10 -16 -23 -16 -29 0 -22 -26 -12 -49 19 -19 25 -21
33 -10 40 20 12 77 11 84 -2z m-685 -2179 c0 -84 -28 -351 -45 -435 -15 -70
-34 -181 -60 -350 -30 -200 -83 -374 -123 -405 -72 -57 -185 18 -264 175 -28
55 -31 69 -31 165 -1 90 3 117 28 190 15 47 39 105 53 130 14 25 30 54 36 65
28 51 234 320 284 371 6 7 12 17 12 23 0 5 6 14 13 18 7 4 28 32 46 61 18 28
37 52 42 52 5 0 9 -27 9 -60z'
          />
          <path
            d='M5351 7590 c0 -13 11 -71 39 -196 5 -23 19 -72 31 -110 35 -113 70
-218 78 -239 5 -11 21 -54 36 -95 15 -41 36 -93 45 -115 10 -22 29 -67 43
-100 14 -33 41 -93 61 -132 20 -40 36 -75 36 -77 0 -3 14 -35 30 -71 17 -36
30 -68 30 -71 0 -3 6 -20 14 -37 39 -91 60 -167 87 -327 13 -75 6 -323 -11
-415 -33 -172 -109 -447 -161 -580 -10 -28 -19 -57 -19 -65 0 -8 -7 -28 -15
-43 -8 -16 -21 -50 -29 -75 -24 -76 -55 -166 -65 -187 -5 -11 -23 -63 -41
-115 -18 -52 -36 -104 -40 -115 -5 -11 -15 -40 -23 -65 -35 -105 -138 -397
-147 -415 -5 -11 -17 -47 -26 -80 -9 -33 -20 -67 -24 -75 -15 -29 -50 -175
-60 -245 -25 -180 0 -415 60 -560 4 -11 14 -36 21 -55 21 -59 83 -196 200
-437 32 -67 59 -126 59 -130 0 -4 6 -20 14 -34 17 -33 58 -158 85 -259 23 -90
51 -330 51 -448 0 -96 -25 -369 -39 -422 -5 -19 -21 -87 -36 -150 -27 -117
-62 -237 -96 -324 -10 -27 -19 -53 -19 -58 0 -6 -6 -24 -14 -41 -8 -18 -26
-61 -41 -97 -51 -121 -116 -263 -196 -423 -27 -54 -49 -100 -49 -103 0 -2 8
-4 19 -4 14 0 35 33 84 133 77 155 157 325 157 333 0 3 6 20 14 37 41 91 118
313 157 453 21 76 28 109 64 294 22 113 31 470 16 609 -18 166 -57 311 -136
506 -36 90 -53 126 -179 388 -36 75 -66 140 -66 143 0 3 -6 20 -14 37 -28 64
-65 184 -77 252 -24 128 -10 451 21 490 5 6 16 45 26 88 10 42 22 77 26 77 4
0 8 9 8 21 0 19 14 59 71 204 10 28 23 64 28 80 5 17 30 89 56 160 26 72 56
155 66 185 10 30 39 114 64 185 90 251 96 270 106 305 6 19 14 44 19 55 4 11
20 58 35 105 15 47 35 110 45 140 10 30 23 78 29 105 5 28 16 77 25 111 38
153 51 397 27 549 -21 138 -82 320 -177 524 -24 51 -44 96 -44 100 0 3 -9 23
-19 42 -11 20 -32 65 -46 100 -15 35 -33 78 -40 94 -28 67 -64 161 -70 185 -3
14 -10 34 -15 45 -4 11 -20 56 -34 100 -14 44 -37 115 -51 159 -14 43 -25 88
-25 100 0 13 -3 26 -7 30 -5 4 -13 24 -19 44 -7 20 -12 30 -13 22z'
          />
          <path
            d='M3915 7303 c-16 -9 -46 -28 -65 -42 -19 -14 -42 -30 -51 -36 -9 -5
-28 -18 -41 -28 -21 -14 -49 -18 -150 -20 -136 -2 -151 -8 -177 -73 -6 -16
-24 -38 -40 -47 -42 -25 -69 -90 -91 -217 -29 -176 -40 -233 -49 -260 -5 -14
-17 -52 -27 -85 -9 -33 -21 -67 -25 -75 -26 -53 -52 -156 -60 -239 -4 -53 -13
-136 -18 -186 -5 -49 -15 -137 -22 -195 -6 -58 -15 -114 -19 -125 -22 -54 -39
-365 -20 -365 6 0 10 23 10 52 0 28 4 65 9 82 13 42 50 336 67 531 21 247 38
323 104 490 11 27 51 183 57 220 28 183 47 277 64 313 25 51 39 54 36 7 -3
-52 17 -44 29 11 14 62 39 110 63 124 11 5 50 10 86 10 36 0 75 5 86 10 15 8
19 7 19 -5 0 -8 -6 -15 -12 -15 -20 0 -128 -100 -128 -117 0 -11 11 -4 33 18
30 31 121 99 132 99 3 0 47 29 98 64 108 74 148 96 177 96 49 0 197 -95 269
-172 32 -35 61 -49 61 -30 0 10 -60 72 -99 102 -18 14 -46 35 -61 48 -15 12
-33 22 -39 22 -6 0 -11 3 -11 8 0 14 -81 42 -123 42 -23 -1 -55 -8 -72 -17z'
          />
        </g>
      </svg>
    </Link>
  );
};

export default Logo;
