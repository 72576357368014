import React from 'react';

import styles from './PriceList.module.scss';

import { CriolipolisesPrice, ServicesPrice } from 'entites';

export const PriceList: React.FC = () => {
  return (
    <section className={styles.price}>
      <CriolipolisesPrice />
      <ServicesPrice />
    </section>
  );
};
