import React, { useState } from 'react';

import ResizeObserver from 'react-resize-observer';
import { useNavigate } from 'react-router-dom';

import styles from './Header.module.scss';

import { Navbar } from 'entites';
import { BurgerBtn } from 'shared/components/BurgerBtn/BurgerBtn';
import Button from 'shared/components/Button/Button';
import Logo from 'shared/components/Logo/Logo';
import { BtnContants } from 'shared/constants/constants';

export const Header: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<number>(0);
  const navigate = useNavigate();

  const mainPage = (): void => {
    navigate('/');
  };

  const toggleNavBtn = (): void => {
    setOpen((prev) => !prev);
  };

  const tabletWidth = 785;

  return (
    <header className={styles.header}>
      <ResizeObserver onResize={(rect) => setIsMobile(rect.width)} />
      <div className={isMobile < tabletWidth ? `${styles.header__logoWrapper}` : `${styles.header__logoWrapper_close}`}>
        <h2 onClick={mainPage} className={styles.header__header}>
          Линия
        </h2>
        <BurgerBtn onClick={toggleNavBtn} openNav={open} />
      </div>
      <nav className={open ? `${styles.header__nav} ${styles.header__nav_open}` : styles.header__nav}>
        <div onClick={isMobile < tabletWidth ? toggleNavBtn : () => setOpen(false)} className={styles.header__logo}>
          <Logo />
        </div>
        <div className={open ? `${styles.burgerBtn__openWrapper}` : `d-none`}>
          <BurgerBtn onClick={toggleNavBtn} openNav={open} />
        </div>
        <div className={styles.header__item}>
          <Navbar closeNav={isMobile < tabletWidth ? toggleNavBtn : () => setOpen(false)} />
          <Button
            sign={true}
            text={BtnContants.sign.name}
            additionalClass={styles.signBtn}
            onClick={() => window.arnicaBooking.open()}
          />
        </div>
      </nav>
      <div onClick={toggleNavBtn} className={open ? 'overlay overlay_open' : 'overlay'}></div>
    </header>
  );
};
