import React from 'react';

interface IClassName {
  className: string;
}

const Toggler: React.FC<IClassName> = ({ className }: IClassName) => {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      fill='#ffffff'
      id='Outline'
      viewBox='0 0 24 24'
      width='512'
      height='512'
    >
      <path d='M23,11H13V1a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1V11H1a1,1,0,0,0-1,1H0a1,1,0,0,0,1,1H11V23a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V13H23a1,1,0,0,0,1-1h0A1,1,0,0,0,23,11Z' />
    </svg>
  );
};

export default Toggler;
