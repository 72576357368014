import React from 'react';

import styles from './SpecialistsCard.module.scss';

import { ISpecialists } from 'shared/types/types';

export const SpecialistsCard: React.FC<ISpecialists> = ({
  name,
  image,
  description,
  id,
  fullName,
  icon,
}: ISpecialists) => {
  return (
    <div id={String(id)} className={styles.specialistCard}>
      <h2 className={styles.specialistCard__header}>
        <span>{fullName}</span>
        <img className={styles.specialistCard__icon} src={icon} alt={name} />
      </h2>
      <div className={styles.specialistCard__content}>
        <div className={styles.specialistCard__imgWrapper}>
          <img className={styles.specialistCard__img} src={image} alt={name} />
        </div>
        <p className={styles.specialistCard__text}>{description}</p>
      </div>
    </div>
  );
};
