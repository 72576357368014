import React from 'react';

import styles from './AdvantagesCard.module.scss';

import { IAdvantages } from 'shared/types/types';

interface AdvantagesProps {
  advantages: IAdvantages;
}

const AdvantagesCard: React.FC<AdvantagesProps> = ({ advantages }: AdvantagesProps) => {
  return (
    <div key={advantages.id} className={styles.advantages__item}>
      <div className={styles.advantages__imgWrapper}>
        <img className={styles.advantages__img} src={advantages.icon} alt={advantages.name} />
      </div>
      <div className={styles.advantages__content}>
        <h3 className={styles.advantages__header}>{advantages.name}</h3>
        <p className={styles.advantages__text}>{advantages.description}</p>
      </div>
    </div>
  );
};

export default AdvantagesCard;
