import React from 'react';

import styles from './OrgInfo.module.scss';

import { Advantages, OrgInfoAbout } from 'entites';

export const OrgInfo: React.FC = () => {
  return (
    <section id='#about' className={styles.orgInfo__section}>
      <OrgInfoAbout />
      <Advantages />
    </section>
  );
};
