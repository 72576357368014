import React from 'react';

import styles from './Specialists.module.scss';

import { SpecialistsCard } from 'entites';
import Button from 'shared/components/Button/Button';
import { BtnContants } from 'shared/constants/constants';
import { dataBase } from 'shared/data/data';
import { disableCopy } from 'shared/model/disableCopy';
import { singBtn } from 'shared/model/MainFunctionBtns';

const data = dataBase.specialists;

export const Specialists: React.FC = () => {
  return (
    <section onMouseDown={disableCopy} className={styles.specialists__section}>
      <ul className={styles.specialistsList}>
        {data.map((spec) => (
          <li key={spec.id} className={styles.specialistsList__item}>
            <SpecialistsCard
              signIn={spec.signIn}
              id={spec.id}
              icon={spec.icon}
              name={spec.name}
              fullName={spec.fullName}
              image={spec.image}
              description={spec.description}
            />
            <Button
              sign={true}
              text={`${BtnContants.sign.name} ${spec.signIn}`}
              onClick={singBtn}
              additionalClass={styles.specialists__btn}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};
