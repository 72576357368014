import AlexPhoto from '../assets/Alex.png';
import gigienaIcon from '../assets/gigiena.svg';
import IrinaPhoto from '../assets/Irina.png';
// import licenseIcon from '../assets/license.svg';
import parkingIcon from '../assets/parking.svg';
import placeIcon from '../assets/place.svg';
import placeholderImg from '../assets/Placeholder-1.png';
import apparatPic from '../assets/procedures/apparat.png';
// import biorevital from '../assets/procedures/biorevital.png';
// import biorevitalMain from '../assets/procedures/biorevital1.png';
import crioPic from '../assets/procedures/criolipolis.png';
import crioPicMain from '../assets/procedures/criolopolis1.png';
import ems from '../assets/procedures/emssculpt.png';
import emsMain from '../assets/procedures/emssculpt1.png';
import fdt from '../assets/procedures/fdt.png';
import fdtMain from '../assets/procedures/fdt1.png';
import rfLift from '../assets/procedures/fr-lift.png';
import rfLiftMain from '../assets/procedures/fr-lift1.png';
// import injectPic from '../assets/procedures/inject.png';
import kavitacia from '../assets/procedures/kavitacia.png';
import kavitaciaMain from '../assets/procedures/kavitacia1.png';
import lipolaserMain from '../assets/procedures/lipolaser.png';
import lipolaser from '../assets/procedures/lipolaser1.png';
// import mezoterapiaMain from '../assets/procedures/mezoterapia.png';
// import mezoterapiaVolos from '../assets/procedures/mezoterapiaVolos.png';
// import mezoterapiaLica from '../assets/procedures/mezotirapiaLica.png';
import sertificateIcon from '../assets/sertification.svg';
import signOnlineIcon from '../assets/signOnline.svg';
import stockBirth from '../assets/stock_birth.jpg';
import stockCrio from '../assets/stock_crio.jpg';
import stockFeedback from '../assets/stock_feedback.jpg';
import stockNewClient from '../assets/stock_newclient.jpg';
// import stockPay from '../assets/stock_pay.jpg';
import specIcon from '../assets/vetka.svg';
import whatsappIcon from '../assets/whatsapp.svg';
import { IData } from '../types/types';

export const dataBase: IData = {
  global: {
    sign: 'Запишитесь прямо сейчас',
    consult: 'Остались вопросы?',
  },
  stocks: [
    {
      id: 1,
      name: 'Для новых клиентов',
      description: 'Всем новым клиентам скидка 15% на первую процедуру!',
      image: `${stockNewClient}`,
      price: '',
    },
    {
      id: 2,
      name: '-50% за первую процедуру',
      description:
        'Только до конца ОКТЯБРЯ! При покупке абонемента на посещение криолиполиза, скидка 50% на первую проведенную процедуру!',
      image: `${stockCrio}`,
      price: '',
    },
    {
      id: 3,
      name: 'В День Рождения!',
      description: 'Скидка в честь дня рождения 10% на все процедуры. Подробности уточняйте у администратора.',
      image: `${stockBirth}`,
      price: '',
    },
    // {
    //   id: 4,
    //   name: 'Удобная оплата',
    //   description:
    //     'При покупке абонемента вы можете воспользоваться рассрочкой 50/50. Подробности уточняйте у администратора.',
    //   image: `${stockPay}`,
    //   price: '',
    // },
    {
      id: 5,
      name: '5% за отзыв',
      description: 'Оставьте отзыв на 2ГИС или на любой другой платформе и получите скидку 5% на любую процедуру.',
      image: `${stockFeedback}`,
      price: '',
    },
  ],
  aboutUs: {
    header: 'Beauty-салон "Линия"',
    subheader: 'Наши преимущества',
    about: [
      'Линия - это салон эстетической косметологии с индивидуальным подходом к каждому своему пациенту. Вас ждут современные аппаратные методики и препараты премиум класса для создания и сохранения вашей красоты и молодости. Для Вас - удобное расположение, гостевая парковка, уютный интерьер.',
      'Линия предлагает широкий спектр услуг для решения эстетических проблем лица и тела, борьбы с возрастными изменениями кожи (anti-age-методики) и лечения различных заболеваний.',
      'Благодаря внедрению современных методик, высочайшему уровню сервиса, богатому технологическому обеспечению Линия поможет вам добиться желаемого результата.',
    ],
    image: `${placeholderImg}`,
    advantages: [
      {
        id: 1,
        name: 'Только сертифицированное оборудование',
        icon: `${sertificateIcon}`,
        description: 'Оборудование имеет все официальные разрешительные и нормативные документы',
      },
      {
        id: 2,
        name: 'Удобная онлайн-запись',
        icon: `${signOnlineIcon}`,
        description:
          'Запишитесь самостоятельно на удобное для вас время прямо сейчас, в любое время суток, без звонка, быстро и современно.',
      },
      {
        id: 3,
        name: 'Соблюдение норм гигиены',
        icon: `${gigienaIcon}`,
        description:
          'Мы используем самые современные антисептики, одноразовые перчатки, средства обработки премиум-класса',
      },
      // {
      //   id: 4,
      //   name: 'Опытные специалисты',
      //   icon: `${licenseIcon}`,
      //   description: 'Медицинская лицензия на проведение косметологических процедур,',
      // },
      // // {
      //   id: 4,
      //   name: 'Наличие медицинской лицензии МинЗдрава РФ',
      //   icon: `${licenseIcon}`,
      //   description: 'Медицинская лицензия на проведение косметологических процедур,',
      // },
      {
        id: 5,
        name: 'Месторасположение',
        icon: `${placeIcon}`,
        description: 'Удобное месторасположение клиники (5 минут от метро Геологическая и Площадь 1905 года)',
      },
      {
        id: 6,
        name: 'Парковка',
        icon: `${parkingIcon}`,
        description: 'Всегда свободные парковочные места для Вашего автомобиля',
      },
    ],
    feedback: '',
  },
  faq: [
    {
      id: 1,
      question: 'Сопровождается ли криолиполиз болезненными ощущениями?',
      answer:
        'Боли пациенты не чувствуют. Возникает только легкий временный дискомфорт из-за всасывания и охлаждения тканей. Может ощущаться втягивание, легкое пощипывание, покалывание, онемение.',
    },
    {
      id: 2,
      question: 'Через какое время видны результаты криолиполиза?',
      answer:
        'Сразу после процедуры фигура не изменится, поскольку организму нужно время на выведение разрушенного жира. Результаты можно увидеть уже через месяц после посещения клиники, а затем эффект будет усиливаться в течение 12 недель. Поскольку изменения происходят незаметно, косметологи нередко делают снимки пациентов до и после криолиполиза, чтобы оценить разницу.',
    },
    {
      id: 3,
      question: 'Какой эффект дает мезотерапия?',
      answer:
        'Мезотерапия помогает решить множество проблем с кожей в любом возрасте, как у женщин, так и у мужчин - увлажняет, повышает тонус кожи, уменьшает морщины, предупреждает преждевременное старение и активно используется в комплексном лечении пигментации, постакне, купероза.',
    },
    {
      id: 4,
      question: 'В каком возрасте и какое время года можно проводить процедуру фотодинамической терапии?',
      answer:
        'Процедура абсолютно безопасна, поэтому проводить ее можно в любом возрасте с 18 лет. Она рекомендована в качестве профилактики первых возрастных изменений кожи для категории 30+. Проводить ее можно в любое время года.',
    },
    {
      id: 5,
      question: 'Подходит ли криолиполиз для мужчин?',
      answer:
        'Криолиполиз подходит для пациентов любого пола и возраста. Мировая статистика показывает, что среди клиентов, решивших прибегнуть к удалению жира с помощью холода, 60% составляют женщины, а 40% – мужчины. На всех этот метод действует одинаково эффективно.',
    },
    {
      id: 6,
      question: 'Можно ли совмещать фотодинамическую терапию с другими процедурами?',
      answer:
        'Технологию Revixan можно применять и до, и после проведения существующих косметологических процедур. Она отлично сочетается практически со всеми видами антиэйдж-методик, усиливая их эффект.',
    },
    {
      id: 7,
      question: 'Сколько держится эффект от мезотерапии лица?',
      answer:
        'Эффект от мезотерапии может сохраняться от трех месяцев до года. Чтобы получить желаемый эффект, необходимо пройти курс 4-10 процедур.',
    },
    {
      id: 8,
      question: 'В чем разница между мезотерапией и биоревитализацей?',
      answer:
        'Мезотерапия больше направлена на верхний слой кожи – его тонус, микроциркуляцию крови, лечение. В то время, как биоревитализация – на более глубокий слой дермы – его увлажнение и восстановление. Выбор той или иной процедуры осуществляется исходя из обозначенных задач пациента, индивидуальных качеств кожи, возраста.',
    },
    {
      id: 9,
      question: 'Вернется ли жир после криолиполиза?',
      answer:
        'Общее количество жировых клеток в теле остается постоянным по достижении взрослого возраста. Клеточные структуры могут становиться больше или меньше по мере набора или потери веса, но общее их число остается неизменным. Криолиполиз разрушает жировые клетки в обработанной области, и они удаляются навсегда.',
    },
  ],
  contacts: {
    header: 'Контакты',
    adress: 'ул. 8 Марта 14И',
    workTime: 'Работаем с 9:00 до 21:00',
    phoneNumber: '+7 922 128-73-72',
    phoneTelNumber: '+7 343 328-73-72',
    email: 'ekbline23@gmail.com',
    links: [{ link: 'https://wa.me/+79221287372', icon: `${whatsappIcon}` }],
  },
  services: {
    global: [
      {
        id: 1,
        name: 'Аппаратные методы',
        image: `${apparatPic}`,
      },
      {
        id: 2,
        name: 'Прочие услуги',
        image: `${emsMain}`,
      },
      // {
      //   id: 2,
      //   name: 'Инъекционные методы',
      //   image: `${injectPic}`,
      // },
    ],
    other: [
      {
        id: 'massage',
        name: 'Массаж',
        image: `${emsMain}`,
        types: [
          {
            id: 1,
            name: 'Классический массаж',
            image: `${emsMain}`,
            fullDescription:
              'Биоревитализация — это процедура введения препаратов на основе гиалуроновой кислоты в глубинные слои кожи. Гиалуроновая кислота словно магнит притягивает и удерживает воду, которая не дает волокнам эластина и коллагена распадаться. Более того, она активирует собственную выработку такой кислоты, запуская процесс естественной регенерации клеток. Все это приводит к тому, что кожа наполняется жизненной силой и заметно разглаживается.',
            indications: [
              'Тусклая и сухая обезвоженная кожа, нуждающаяся в глубинном увлажнении',
              'Появление глубоких морщин и общее снижение упругости кожи',
              'Темные круги под глазами и бледный (болезненный) цвет лица',
              'Пигментация, застойные пятна и купероз',
              'Постакне и последствия угревой болезни',
              'Брыли - обвисшие участки кожи и подкожно-жировой клетчатки в области щек, нижней челюсти и шеи',
            ],
            contraindications: [
              'Беременность, период лактации',
              'Индивидуальная непереносимость активных веществ препарата',
              'Обострение хронических заболеваний',
              'Онкология',
              'Воспалительные процессы в зоне предполагаемой инъекции',
              'Аутоиммунные заболевания',
              'Нарушения свертываемости крови',
              'Острые инфекционные заболевания (в том числе, грипп, ОРЗ, герпес)',
              'Нежелательно проводить процедуру в первые дни менструального цикла',
              'Сердечно-сосудистые заболевания',
            ],
            preparing: 'Перед проведением процедуры обязательна консультация врача.',
            price: '6000 ₽',
            icon: `${specIcon}`,
          },
          {
            id: 2,
            name: 'Лимфодренажный массаж',
            image: `${emsMain}`,
            fullDescription:
              'Биоревитализация — это процедура введения препаратов на основе гиалуроновой кислоты в глубинные слои кожи. Гиалуроновая кислота словно магнит притягивает и удерживает воду, которая не дает волокнам эластина и коллагена распадаться. Более того, она активирует собственную выработку такой кислоты, запуская процесс естественной регенерации клеток. Все это приводит к тому, что кожа наполняется жизненной силой и заметно разглаживается.',
            indications: [
              'Тусклая и сухая обезвоженная кожа, нуждающаяся в глубинном увлажнении',
              'Появление глубоких морщин и общее снижение упругости кожи',
              'Темные круги под глазами и бледный (болезненный) цвет лица',
              'Пигментация, застойные пятна и купероз',
              'Постакне и последствия угревой болезни',
              'Брыли - обвисшие участки кожи и подкожно-жировой клетчатки в области щек, нижней челюсти и шеи',
            ],
            contraindications: [
              'Беременность, период лактации',
              'Индивидуальная непереносимость активных веществ препарата',
              'Обострение хронических заболеваний',
              'Онкология',
              'Воспалительные процессы в зоне предполагаемой инъекции',
              'Аутоиммунные заболевания',
              'Нарушения свертываемости крови',
              'Острые инфекционные заболевания (в том числе, грипп, ОРЗ, герпес)',
              'Нежелательно проводить процедуру в первые дни менструального цикла',
              'Сердечно-сосудистые заболевания',
            ],
            preparing: 'Перед проведением процедуры обязательна консультация врача.',
            price: '6000 ₽',
            icon: `${specIcon}`,
          },
          {
            id: 3,
            name: 'Медицинский массаж',
            image: `${emsMain}`,
            fullDescription:
              'Биоревитализация — это процедура введения препаратов на основе гиалуроновой кислоты в глубинные слои кожи. Гиалуроновая кислота словно магнит притягивает и удерживает воду, которая не дает волокнам эластина и коллагена распадаться. Более того, она активирует собственную выработку такой кислоты, запуская процесс естественной регенерации клеток. Все это приводит к тому, что кожа наполняется жизненной силой и заметно разглаживается.',
            indications: [
              'Тусклая и сухая обезвоженная кожа, нуждающаяся в глубинном увлажнении',
              'Появление глубоких морщин и общее снижение упругости кожи',
              'Темные круги под глазами и бледный (болезненный) цвет лица',
              'Пигментация, застойные пятна и купероз',
              'Постакне и последствия угревой болезни',
              'Брыли - обвисшие участки кожи и подкожно-жировой клетчатки в области щек, нижней челюсти и шеи',
            ],
            contraindications: [
              'Беременность, период лактации',
              'Индивидуальная непереносимость активных веществ препарата',
              'Обострение хронических заболеваний',
              'Онкология',
              'Воспалительные процессы в зоне предполагаемой инъекции',
              'Аутоиммунные заболевания',
              'Нарушения свертываемости крови',
              'Острые инфекционные заболевания (в том числе, грипп, ОРЗ, герпес)',
              'Нежелательно проводить процедуру в первые дни менструального цикла',
              'Сердечно-сосудистые заболевания',
            ],
            preparing: 'Перед проведением процедуры обязательна консультация врача.',
            price: '6000 ₽',
            icon: `${specIcon}`,
          },
        ],
      },
    ],
    inject: [],

    // inject: [
    //   {
    //     id: 'biorevitalizatia',
    //     name: 'Биоревитализация',
    //     image: `${biorevitalMain}`,
    //     types: [
    //       {
    //         id: 1,
    //         name: 'Биоревитализация',
    //         image: `${biorevital}`,
    //         fullDescription:
    //           'Биоревитализация — это процедура введения препаратов на основе гиалуроновой кислоты в глубинные слои кожи. Гиалуроновая кислота словно магнит притягивает и удерживает воду, которая не дает волокнам эластина и коллагена распадаться. Более того, она активирует собственную выработку такой кислоты, запуская процесс естественной регенерации клеток. Все это приводит к тому, что кожа наполняется жизненной силой и заметно разглаживается.',
    //         indications: [
    //           'Тусклая и сухая обезвоженная кожа, нуждающаяся в глубинном увлажнении',
    //           'Появление глубоких морщин и общее снижение упругости кожи',
    //           'Темные круги под глазами и бледный (болезненный) цвет лица',
    //           'Пигментация, застойные пятна и купероз',
    //           'Постакне и последствия угревой болезни',
    //           'Брыли - обвисшие участки кожи и подкожно-жировой клетчатки в области щек, нижней челюсти и шеи',
    //         ],
    //         contraindications: [
    //           'Беременность, период лактации',
    //           'Индивидуальная непереносимость активных веществ препарата',
    //           'Обострение хронических заболеваний',
    //           'Онкология',
    //           'Воспалительные процессы в зоне предполагаемой инъекции',
    //           'Аутоиммунные заболевания',
    //           'Нарушения свертываемости крови',
    //           'Острые инфекционные заболевания (в том числе, грипп, ОРЗ, герпес)',
    //           'Нежелательно проводить процедуру в первые дни менструального цикла',
    //           'Сердечно-сосудистые заболевания',
    //         ],
    //         preparing: 'Перед проведением процедуры обязательна консультация врача.',
    //         price: '6000 ₽',
    //         icon: `${specIcon}`,
    //       },
    //     ],
    //   },
    //   {
    //     id: 'mezoterapia',
    //     name: 'Мезотерапия',
    //     // description:
    //     // 'Значимость этих проблем настолько очевидна, что укрепление и развитие структуры требуют определения и уточнения модели развития. Равным образом постоянный количественный рост и сфера нашей активности позволяет выполнять важные задания по разработке соответствующий условий активизации',
    //     image: `${mezoterapiaMain}`,
    //     types: [
    //       {
    //         id: 1,
    //         name: 'Мезотерапия',
    //         image: `${mezoterapiaLica}`,
    //         fullDescription:
    //           'Мезотерапия - это  малоинвазивная процедура, при помощи которой кожа насыщается комплексом витаминов, минералов, аминокислот и другими полезными веществами. Данная процедура, благодаря уникальному составу препаратов, оказывает косметическое и лечебное воздействие на кожу, позволяя побороть целый ряд недостатков и первых возрастных изменений. Эффект от процедуры достигается благодаря воздействию компонентов препарата на кожу и тонкой иглы для инъекций. Во время процедуры кожа получает микротравмы, которые активизируют выработку собственного коллагена и эластина - это улучшает общее состояние кожи, делает её более упругой и позволяет избавиться от небольших морщин.',
    //         indications: [
    //           'Возрастные изменения кожи, снижение тонуса и эластичности',
    //           'Акне, постакне',
    //           'Гиперпигментация',
    //           'Купероз, розацеа',
    //           'Локальные жировые отложения, целлюлит',
    //           'Рубцы, растяжки',
    //           'Подготовка и реабилитация после разного рода манипуляции',
    //         ],
    //         contraindications: [
    //           'Аллергия на компоненты коктейля',
    //           'Низкий болевой порог, патологическая боязнь иглы',
    //           'Острые заболевания (ОРВИ, любое недомогание с температурой), герпес в стадии - обострения',
    //           'Эпилепсия',
    //           'Кожные заболевания в области инъекций',
    //           'Приём антикоагулянтов и нарушение свертывания крови',
    //           'Склонность к келоидным рубцам',
    //           'Соматические заболевания в стадии декомпенсации',
    //           'Онкология в анамнезе (с разрешения онколога)',
    //           'Беременность, лактация',
    //         ],
    //         preparing: 'Перед проведением процедуры обязательна консультация врача.',
    //         price: '5000 ₽',
    //         icon: `${specIcon}`,
    //       },
    //       {
    //         id: 2,
    //         name: 'Мезотерапия волосистой части головы',
    //         image: `${mezoterapiaVolos}`,
    //         fullDescription:
    //           'Мезотерапия головы - это введение биологически активных веществ в кожу волосистой части с целью восстановления и стимуляции естественных обменных процессов. Это один из самых эффективных и безопасных методов оздоровления и лечения выпадения волос у мужчин и женщин в современной косметологии и трихологии (наука о волосах). Мезотерапия помогает решить такие проблемы, как ломкость и сухость волос и кожи головы, повышенная сальность, тусклость, сечение кончиков. Мезококтейль подбирается индивидуально для каждого пациента в соответствии с его проблемой.',
    //         indications: [
    //           'Интенсивное выпадение волос',
    //           'Истощение волос, ослабленные и тонкие волосы',
    //           'Сухость и ломкость волос',
    //           'Медленный рост волос',
    //           'Чрезмерное сечение волос',
    //           'Перхоть, жирная себорея',
    //           'Чрезмерная жирность кожи головы',
    //         ],
    //         contraindications: [
    //           'Беременность, период лактации',
    //           'Некоторые заболевания кожи в состоянии обострения (псориаз, экзема, атопический дерматит и др.)',
    //           'Микробные процессы в области введения (герпес, пиодермия, грибковое поражение)',
    //           'Заболевания эндокринной системы (декомпенсированный сахарный диабет, тиреотоксикоз и др.)',
    //           'Склонность к формированию келоидных и гипертрофических рубцов',
    //           'Онкологические заболевания',
    //         ],
    //         preparing: 'Перед проведением процедуры обязательна консультация врача.',
    //         price: '4500 ₽',
    //         icon: `${specIcon}`,
    //       },
    //     ],
    //   },
    // ],
    apparat: [
      {
        id: 'emssculpt',
        name: 'EMSculpt',
        // description:
        // 'Значимость этих проблем настолько очевидна, что укрепление и развитие структуры требуют определения и уточнения модели развития. Равным образом постоянный количественный рост и сфера нашей активности позволяет выполнять важные задания по разработке соответствующий условий активизации',
        image: `${emsMain}`,
        types: [
          {
            id: 1,
            name: 'EMSculpt',
            image: `${ems}`,
            fullDescription:
              'EMSCULPT (Эмскалпт) - это первый неинвазивный аппарат для проведения эстетических процедур с целью одновременного укрепления мышц и уменьшения жировых отложений. Данная методика позволяет создать более стройное тело, сделать его подтянутым, проработать контуры, а также уменьшить локальные жировые отложения, одновременно прокачивая мышцы, скрывающиеся под ними.',
            indications: [
              'Дряблая кожа рук, ног, бедер, живота и ягодиц',
              'Целлюлит',
              'Необходимость восстановления тонуса мышц (например, после родов)',
              'Отечность',
              'Необходимость общей коррекции контуров тела',
              'Тугоплавкие жировые отложения',
            ],
            contraindications: [
              'Наличие кардиостимулятора, имплантированных дефибрилляторов, нейростимуляторов и т. п.',
              'Наличие электронных и металлических имплантов, лекарственных помп и т. п.',
              'Заболевания сердечно-сосудистой системы, системные заболевания в стадии декомпенсации (сахарный диабет, гипертоническая болезнь, аутоиммунные заболевания)',
              'Острые инфекционные заболевания (в том числе, грипп, ОРЗ, герпес)',
              'Прием лекарственных средств: антибиотиков, антикоагулянтов',
              'Беременность и период грудного вскармливания, нежелательно проводить процедуру в первые дни менструального цикла',
              'Онкологические заболевания',
              'Недавно проведенные хирургические операции',
              'Эпилепсия',
            ],
            preparing:
              'Процедура на аппарате EMSculpt не требует специальной подготовки и проходит абсолютно безболезненно. Но перед проведением процедуры необходима консультация врача.',
            price: '1500 ₽',
            icon: `${specIcon}`,
          },
        ],
      },
      {
        id: 'kriolipoliz',
        name: 'Криолиполиз',
        // description:
        // 'Значимость этих проблем настолько очевидна, что укрепление и развитие структуры требуют определения и уточнения модели развития. Равным образом постоянный количественный рост и сфера нашей активности позволяет выполнять важные задания по разработке соответствующий условий активизации',
        image: `${crioPic}`,
        types: [
          {
            id: 1,
            name: 'Криолиполиз',
            image: `${crioPicMain}`,
            fullDescription:
              'Криолиполиз — современная методика избавления от лишних объемов на лице и теле, в основе которой лежит воздействие низких температур на подкожно-жировой слой. Дело в том, что клетки жировой ткани, в отличие от клеток кожи или мышц, не переносят охлаждения и начинают погибать при низких температурах. Погибшие жировые клетки выводятся из организма через лимфатическую систему. После криолиполиза на аппарате CRYO S360 первые результаты можно увидеть лишь через 3-4 недели, а максимальная эффективность достигается спустя 2-3 месяца. За одну процедуру можно избавиться от 2 до 5 см в объеме. У нас установлена новая модель аппарата последней версии!',
            indications: [
              'Лишний вес и неудовлетворение фигурой',
              'Наличие так называемого застойного гормонального жира',
              'У пациента имеется локальные образования жировой ткани, от которых он хочет избавиться',
              'Долго не исчезает лишний вес от диет или спорта',
            ],
            contraindications: [
              'Период беременности и лактации',
              'Онкологические заболевания, хронические болезни почек и печени',
              'Повреждения кожи в выбранной области',
              'Наличие дефибриллятора или кардиостимулятора',
              'Индивидуальная чувствительность к холоду',
              'Заболевания нервной системы',
              'Кожные заболевания',
              'Аутоиммунные или инфекционные заболевания',
              'Герпес, другие инфекции или воспаления кожи',
              'Эпилептические припадки',
            ],
            preparing:
              'Этапы подготовки к сеансу криолиполиза включают в себя следующее: предварительная консультация с врачом. За пару дней до процедуры надо отказаться от алкоголя, жареной, соленой, острой пищи. Также за 1-1,5 часа до процедуры необходимо воздержаться от приема пищи.',
            price: '1900 ₽',
            icon: `${specIcon}`,
          },
        ],
      },
      {
        id: 'rf-lifting',
        name: 'RF-лифтинг',
        image: `${rfLiftMain}`,
        types: [
          {
            id: 1,
            name: 'RF-лифтинг',
            image: `${rfLift}`,
            fullDescription:
              'RF-лифтинг (радиоволновой лифтинг) — процедура, основанная на радиоволновом методе разогрева дермы и гиподермы. Принцип действия RF-лифтинга основан на физическом воздействии энергии на живые ткани: радиочастотные волны диапазона от 38 до 42 тысяч герц обладают избирательным нагревающим действием на глубокие слои кожи и подкожной жировой клетчатки. В процессе процедуры ткани, на которое оказано воздействие, прогреваются до температуры 44 градуса цельсия. Это значение выше температуры денатурации белковых молекул (42 градуса). Следовательно, при систематической воздействии РФ-лифтинга в живой ткани начинают образовываться волокна плотного коллагена и фибрин, что в совокупности образует мощную соединительнотканную сеть, эффективно поддерживающую мягкие ткани.',
            indications: [
              'Антицеллюлитная терапия',
              'Купероз лица, ног',
              'Мимические морщины и складки',
              'Стрии (растяжки различной этиологии)',
              'Поддержание тонуса кожи',
            ],
            contraindications: [
              'Заболевания соединительной ткани',
              'Острый период вирусной или бактериальной инфекции, обострения хронических болезней',
              'Беременность, лактация или менструация',
              'Наличие протезов, имплантатов (в том числе золотое армирование), водителей ритма',
              'Одновременное получение пациентом ботоксных инъекций',
              'Высокое давление в настоящий момент или тяжелая форма гипертонии',
              'Раны, экземы, активный воспалительный процесс на коже',
              'Онкологические заболевания',
              'Диабет любого типа',
            ],
            preparing:
              'Процедура не требует специальной подготовки, но перед ее проведением необходима консультация врача.',
            price: '1800 ₽',
            icon: `${specIcon}`,
          },
        ],
      },
      {
        id: 'fdt',
        name: 'ФДТ-терапия',
        image: `${fdtMain}`,
        types: [
          {
            id: 1,
            name: 'Фотодинамическая терапия',
            image: `${fdt}`,
            fullDescription:
              'Фотодинамическая терапия является результатом комбинированного действия трех нетоксичных компонентов: лазерного света, фотосенсибилизатора и кислорода. Основные эффекты от процедуры ФДТ - это улучшение внешнего вида кожи за счет нормализации обменных и регуляторных процессов и механизмов деления клеток кожи, повышение плотности кожи за счет стимуляция пролиферации фибробластов со значительным увеличением выработки коллагена в дерме, уменьшение количества мелких морщин после снижения экспрессии матриксных металлопротеиназ матрикса, разрушающих коллаген и эластин, оздоровление кожи, восстановление клеточного и гуморального иммунитета.',
            indications: [
              'Акне и постакне, розацеа, псориаз, красный плоский лишай, кератозы ',
              'Проявления ПВИ (подошвенные бородавки, остроконечные кондиломы)',
              'Гипертрофические рубцы',
              'Гидраденит, гиперплазия сальных желез',
              'Кератоакантома',
              'Онихомикоз',
              'Себорейный дерматит',
              'Эластоз и другие дерматозы',
              'Лечение и профилактика фотостарения',
              'Стимуляция выработки коллагена',
              'Восстановление и улучшение заживления тканей и лечение осложнений после инвазивных методов эстетической медицины',
              'Реабилитация после пластических операций',
            ],
            contraindications: [
              'Повышенная чувствительность к компонентам препаратов',
              'Повреждения кожи и острый воспалительный процесс',
              'Беременность и лактация',
              'Обострение хронических заболеваний',
              'Лихорадочные состояния',
              'Психические нарушения и неврологические состояния',
              'Эпилепсия',
              'Фотодерматиты',
              'Кровотечения',
              'Сердечно-сосудистые заболевания в стадии декомпенсации',
            ],
            preparing:
              'Специальной подготовки к фотодинамической терапии не требуется. За 12-15 дней необходимо полностью отказаться от солярия и приема солнечных ванн — наличие загара может спровоцировать ожог. За 2-3 дня из регулярного ухода необходимо убрать спиртосодержащие средства. За 3 дня до сеанса — не посещать сауны или бассейна',
            price: '1000 ₽',
            icon: `${specIcon}`,
          },
        ],
      },
      {
        id: 'kavitacia',
        name: 'Кавитация',
        image: `${kavitaciaMain}`,
        types: [
          {
            id: 1,
            name: 'Кавитация',
            image: `${kavitacia}`,
            fullDescription:
              'Кавитация — процедура в косметологии, суть которой заключается во влиянии низкочастотного ультразвука через специальный аппликатор на ткани подкожно-жирового слоя с целью их разрушения. В отличие от криолиполиза этот метод лечения дает результат не за один сеанс, а за 7-10. Как работает кавитация? Аппарат излучает низкочастотный ультразвук, внутри жировых клеток (адипоцитов) в ответ на ультразвуковое воздействие образуется небольшой пузырек, постепенно он увеличивается. Адипоцит раздувается, мембрана (стенка) клетки не выдерживает напряжения и разрывается.',
            indications: [
              'Фиброз, целлюлит на бедрах и ягодицах, «апельсиновая корка»',
              'Дефекты после ранее проведенной хирургической липосакции',
              'Излишки кожи, дряблость, плохой тонус',
              'Объемные отложения жира в области живота, плеч, спины, боков, бедер, ягодиц',
            ],
            contraindications: [
              'Беременность и лактация',
              'Аутоиммунные заболевания',
              'Болезни и повреждения кожи в зоне обработки',
              'Склонность к образованию тромбов',
              'Онкологические заболевания',
              'Сахарный диабет',
              'Болезни крови, сосудов',
              'Почечная недостаточность',
              'Грыжи',
              'Обострение хронических заболеваний',
            ],
            preparing:
              'Перед сеансом потребуется соблюдать простые правила: За 3 дня до УЗ-кавитации исключите из рациона калорийные продукты, а также жареные, копченые и пряные блюда. Также следует поддерживать адекватный водный баланс: выпивать до 2-3 л чистой негазированной воды в день. Кроме того необходимо исключить алкоголь.',
            price: '1500 ₽',
            icon: `${specIcon}`,
          },
        ],
      },
      {
        id: 'lipolaser',
        name: 'Лазерный липолиз',
        image: `${lipolaserMain}`,
        types: [
          {
            id: 1,
            name: 'Лазерный липолиз',
            image: `${lipolaser}`,
            fullDescription:
              'Лазерным липолиз - малоинвазивная процедура коррекции контуров фигуры, когда оказывается точечное воздействие на жировые отложения в проблемных зонах. В ходе такой процедуры можно успешно моделировать объем в нужной области (способом уменьшения), не прибегая к хирургическому вмешательству. Сама процедура лазерного липолиза абсолютно безболезненна. Пациент ощущает тепло, приятное расслабление. Процедура лазерного липолиза воздействовует на генерацию новых волокон коллагена, что делает аппаратную процедуру эффективной вдвойне.',
            indications: [
              'Устранение нежелательных жировых отложений, в том числе в труднодоступных местах',
              'Борьба с целлюлитом и растяжками',
              'Гармонизация контуров тела',
              'Ускорение обмена веществ',
              'Улучшение качества кожи',
              'Отсутствие или недостаточный эффект применения других методик похудения',
            ],
            contraindications: [
              'Онкологические заболевания',
              'Тяжелые заболевания сердечно-сосудистой системы, состояние после инсульта',
              'Варикозное расширение вен в зоне воздействия',
              'Сахарный диабет',
              'Нарушение свертываемости крови',
              'Беременность и период лактации',
            ],
            preparing:
              'Процедура хоть и является безопасной, подготовиться к ней стоит заранее. Специалист косметолог поможет правильно разработать курс процедур и выявить противопоказания при их наличии. Непосредственно перед проведением процедуры, рекомендуется за несколько дней сесть на молочно-растительную диету. Исключить максимально мясо, но оставить рыбу. Полностью исключить употребление алкоголя и отказаться от курения, не посещать солярий.',
            price: '1000 ₽',
            icon: `${specIcon}`,
          },
        ],
      },
    ],
  },
  footer: {
    name: 'ООО «Линия»',
    years: '© 2023',
    license: 'Мед.лицензия №М000-00000-66/00000000',
    requisites: '6679168646 / 667901001',
    confidentialPolitic: 'Политика конфиденциальности и пользовательское соглашение',
    confidentialPoliticLink: '/123',
    production: {
      name: 'Разработал Александр Клёсов',
      link: 'https://github.com/Nexuslolz',
    },
  },
  specialists: [
    {
      id: 1,
      icon: `${specIcon}`,
      name: 'Ирина',
      fullName: 'Ирина',
      image: `${IrinaPhoto}`,
      description: `
        Врач-косметолог Ирина!
        Ирина имеет высшее медицинское образование, закончила клиническую ординатуру по дерматовенерологии на базе УрНИИДВиИ, а также прошла переподготовку по врачебной косметологии в УГМУ и имеет сертификаты об окончании специализированных курсов.
        Работает в сфере косметологии уже более 5 лет и постоянно совершенствует и развивает свои навыки и знания.
        Выполняет процедуры аккуратно с соблюдением правил безопасности и гигиены.
        Влюблена в свою работу и получает от нее огромное удовольствие`,
      achievement: '',
      direction: '',
      signIn: 'к Ирине',
    },
    {
      id: 2,
      icon: `${specIcon}`,
      name: 'Александр',
      fullName: 'Александр',
      image: `${AlexPhoto}`,
      description: `
        Александр — врач-дерматолог. Александр также имеет высшее медицинское образование, закончил ординатуру по дерматовенерологии на базе УрНИИДВиИ, проходил различные курсы по повышению своей квалификации.
        Опыт работы в сфере уже более 3-х лет.
        Александр с удовольствием проконсультирует вас по любому вопросу, даст рекомендации с учетом ваших индивидуальных особенностей.`,
      achievement: '',
      direction: '',
      signIn: 'к Александру',
    },
  ],
  pricesCrio: [
    {
      serviceName: 'Криолиполиз - разовое посещение',
      params: [
        {
          head: '1 насадка',
          params: [
            {
              time: '30 минут',
              price: '1900 ₽',
            },
            {
              time: '45 минут',
              price: '3000 ₽',
            },
            {
              time: '60 минут',
              price: '4590 ₽',
            },
          ],
        },
        {
          head: '2 насадки',
          params: [
            {
              time: '30 минут',
              price: '2900 ₽',
            },
            {
              time: '45 минут',
              price: '4490 ₽',
            },
            {
              time: '60 минут',
              price: '5990 ₽',
            },
          ],
        },
        {
          head: '3 насадки',
          params: [
            {
              time: '30 минут',
              price: '4490 ₽',
            },
            {
              time: '45 минут',
              price: '6490 ₽',
            },
            {
              time: '60 минут',
              price: '7490 ₽',
            },
          ],
        },
        {
          head: '4 насадки',
          params: [
            {
              time: '30 минут',
              price: '5490 ₽',
            },
            {
              time: '45 минут',
              price: '7990 ₽',
            },
            {
              time: '60 минут',
              price: '8590 ₽',
            },
          ],
        },
      ],
    },
    {
      serviceName: 'Криолиполиз - абонемент на 3 посещения',
      params: [
        {
          head: '1 насадка',
          params: [
            {
              time: '30 минут',
              price: '5590 ₽',
            },
            {
              time: '45 минут',
              price: '7990 ₽',
            },
            {
              time: '60 минут',
              price: '11490 ₽',
            },
          ],
        },
        {
          head: '2 насадки',
          params: [
            {
              time: '30 минут',
              price: '8490 ₽',
            },
            {
              time: '45 минут',
              price: '10990 ₽',
            },
            {
              time: '60 минут',
              price: '12990 ₽',
            },
          ],
        },
        {
          head: '3 насадки',
          params: [
            {
              time: '30 минут',
              price: '12990 ₽',
            },
            {
              time: '45 минут',
              price: '14590 ₽',
            },
            {
              time: '60 минут',
              price: '16000 ₽',
            },
          ],
        },
        {
          head: '4 насадки',
          params: [
            {
              time: '30 минут',
              price: '14490 ₽',
            },
            {
              time: '45 минут',
              price: '15990 ₽',
            },
            {
              time: '60 минут',
              price: '18490 ₽',
            },
          ],
        },
      ],
    },
    {
      serviceName: 'Криолиполиз - абонемент на 5 посещений',
      params: [
        {
          head: '1 насадка',
          params: [
            {
              time: '30 минут',
              price: '9490 ₽',
            },
            {
              time: '45 минут',
              price: '10990 ₽',
            },
            {
              time: '60 минут',
              price: '12490 ₽',
            },
          ],
        },
        {
          head: '2 насадки',
          params: [
            {
              time: '30 минут',
              price: '13990 ₽',
            },
            {
              time: '45 минут',
              price: '15490 ₽',
            },
            {
              time: '60 минут',
              price: '16990 ₽',
            },
          ],
        },
        {
          head: '3 насадки',
          params: [
            {
              time: '30 минут',
              price: '18490 ₽',
            },
            {
              time: '45 минут',
              price: '18990 ₽',
            },
            {
              time: '60 минут',
              price: '22490 ₽',
            },
          ],
        },
        {
          head: '4 насадки',
          params: [
            {
              time: '30 минут',
              price: '23990 ₽',
            },
            {
              time: '45 минут',
              price: '25490 ₽',
            },
            {
              time: '60 минут',
              price: '26990 ₽',
            },
          ],
        },
      ],
    },
  ],
  prices: [
    {
      serviceName: 'Кавитация',
      params: [
        {
          serviceParams: 'Разовое посещение',
          price: '1500 ₽',
        },
        {
          serviceParams: 'Абонемент на 5 посещений',
          price: '5200 ₽',
        },
        {
          serviceParams: 'Абонемент на 10 посещений',
          price: '8500 ₽',
        },
      ],
    },
    {
      serviceName: 'EMSculpt',
      params: [
        {
          serviceParams: 'Пробное посещение',
          price: '1500 ₽',
        },
        {
          serviceParams: 'Разовое посещение',
          price: '2000 ₽',
        },
        {
          serviceParams: 'Абонемент на 5 посещений',
          price: '8300 ₽',
        },
        {
          serviceParams: 'Абонемент на 10 посещений',
          price: '16000 ₽',
        },
      ],
    },
    {
      serviceName: 'RF-Лифтинг',
      params: [
        {
          serviceParams: 'Разовое посещение',
          price: '1800 ₽',
        },
        {
          serviceParams: 'Абонемент на 5 посещений',
          price: '7000 ₽',
        },
        {
          serviceParams: 'Абонемент на 10 посещений',
          price: '13990 ₽',
        },
      ],
    },
    {
      serviceName: 'Лазерный липолиз',
      params: [
        {
          serviceParams: 'Разовое посещение',
          price: '1000 ₽',
        },
        {
          serviceParams: 'Абонемент на 5 посещений',
          price: '4800 ₽',
        },
        {
          serviceParams: 'Абонемент на 10 посещений',
          price: '8500 ₽',
        },
      ],
    },
    // {
    //   serviceName: 'Биоревитализация',
    //   params: [
    //     {
    //       serviceParams: 'REVI Strong 1ml',
    //       price: '9000 ₽',
    //     },
    //     {
    //       serviceParams: 'REVI Strong 2ml',
    //       price: '15000 ₽',
    //     },
    //     {
    //       serviceParams: 'REVI Style 1ml',
    //       price: '8500 ₽',
    //     },
    //     {
    //       serviceParams: 'REVI Style 2ml',
    //       price: '14000 ₽',
    //     },
    //     {
    //       serviceParams: 'REVI Eyes 1ml',
    //       price: '6000 ₽',
    //     },
    //     {
    //       serviceParams: 'REVI Silk 1ml',
    //       price: '9200 ₽',
    //     },
    //     {
    //       serviceParams: 'REVI Silk 2ml',
    //       price: '14500 ₽',
    //     },
    //     {
    //       serviceParams: 'Bellarti Hydrate 1ml',
    //       price: '6000 ₽',
    //     },
    //     {
    //       serviceParams: 'Bellarti Hydrate 2ml',
    //       price: '10000 ₽',
    //     },
    //     {
    //       serviceParams: 'Bellarti Lift 1ml',
    //       price: '6000 ₽',
    //     },
    //     {
    //       serviceParams: 'Bellarti Lift 2ml',
    //       price: '10000 ₽',
    //     },
    //     {
    //       serviceParams: 'Meso-Wharton P199 1.5ml',
    //       price: '13000 ₽',
    //     },
    //     {
    //       serviceParams: 'Meso-Xanthin F199 1.5ml',
    //       price: '12000 ₽',
    //     },
    //     {
    //       serviceParams: 'MesoEye C71 1ml',
    //       price: '9000 ₽',
    //     },
    //     {
    //       serviceParams: 'Nucleospire Revitalizing Complex A 1.3ml',
    //       price: '7500 ₽',
    //     },
    //     {
    //       serviceParams: 'Nucleospire Revitalizing Complex A 2ml',
    //       price: '12000 ₽',
    //     },
    //     {
    //       serviceParams: 'Nucleospire DNA-RNA 2% 1.3ml',
    //       price: '7200 ₽',
    //     },
    //     {
    //       serviceParams: 'Nucleospire DNA-RNA 2% 2ml',
    //       price: '11800 ₽',
    //     },
    //     {
    //       serviceParams: 'MesoEye C71 1ml',
    //       price: '9000 ₽',
    //     },
    //     {
    //       serviceParams: 'Belotero Soft 1ml (Бланширование морщин)',
    //       price: '15000 ₽',
    //     },
    //   ],
    // },
    // {
    //   serviceName: 'Мезотерапия',
    //   params: [
    //     {
    //       serviceParams: 'Hyrdo Line Peptide 2ml',
    //       price: '5600 ₽',
    //     },
    //     {
    //       serviceParams: 'Hyrdo Line P Anti-Wrinkles 1.3ml',
    //       price: '5000 ₽',
    //     },
    //     {
    //       serviceParams: 'AND Restart Peptide 2ml',
    //       price: '5500 ₽',
    //     },
    //   ],
    // },
    // {
    //   serviceName: 'Мезотерапия волосистой части головы',
    //   params: [
    //     {
    //       serviceParams: 'Hair X Peptide 1.3ml',
    //       price: '4500 ₽',
    //     },
    //     {
    //       serviceParams: 'Hair X DNA Peptide 2ml',
    //       price: '5800 ₽',
    //     },
    //     {
    //       serviceParams: 'Hair X Vita Line B+ 4ml',
    //       price: '6000 ₽',
    //     },
    //   ],
    // },
    // {
    //   serviceName: 'Фотодинамическая терапия',
    //   params: [
    //     {
    //       serviceParams: '1 зона/лицо/руки',
    //       price: '3600 ₽',
    //     },
    //     {
    //       serviceParams: '2 зоны/лицо + шея',
    //       price: '4500 ₽',
    //     },
    //     {
    //       serviceParams: 'Лицо + шея/2 зоны + декольте/3 зоны',
    //       price: '5500 ₽',
    //     },
    //     {
    //       serviceParams: 'Кожа головы (полностью)',
    //       price: '4500 ₽',
    //     },
    //     {
    //       serviceParams: 'Кожа головы (локально/алопеция)',
    //       price: '1000 ₽',
    //     },
    //     {
    //       serviceParams: 'Лечение ногтей (полностью)',
    //       price: '4500 ₽',
    //     },
    //     {
    //       serviceParams: 'Лечение ногтей (1 рука/1 стопа)',
    //       price: '3500 ₽',
    //     },
    //   ],
    // },
  ],
};
