import React, { useState } from 'react';

import ResizeObserver from 'react-resize-observer';
import { Link } from 'react-router-dom';

import styles from './ContactsFrame.module.scss';

import Button from 'shared/components/Button/Button';
import { BtnContants } from 'shared/constants/constants';
import { dataBase } from 'shared/data/data';
import WhatsappIcon from 'shared/Icons/WhatsappIcon';
import { singBtn } from 'shared/model/MainFunctionBtns';

const data = dataBase.contacts;

export const ContactsFrame: React.FC = () => {
  const [size, setSize] = useState<number>(0);
  const mobileWidth = 1000;

  const setTel = (telNumber: string): string => {
    const tel = telNumber.split(' ').join('').split('-').join('');
    return tel;
  };

  return (
    <>
      <ResizeObserver onResize={(rect) => setSize(rect.width)} />
      <div className={size < mobileWidth ? `${styles.contacts} ${styles.contacts_mobile}` : styles.contacts}>
        <h3 className={styles.contacts__text}>{data.adress}</h3>
        <p className={styles.contacts__text}>{data.workTime}</p>
        <a href={`tel:${setTel(data.phoneNumber)}`} className={styles.contacts__text}>
          {data.phoneNumber}
        </a>
        <a href={`tel:${setTel(data.phoneTelNumber)}`} className={styles.contacts__text}>
          {data.phoneTelNumber}
        </a>
        <p className={styles.contacts__text}>{data.email}</p>
        <ul className={styles.contactsList}>
          {data.links.map((link) => (
            <li key={link.link} className={styles.contactsList__item}>
              <Link className={styles.contactsList__itemImg} key={link.link} target='_blank' to={link.link}>
                <WhatsappIcon />
              </Link>
            </li>
          ))}
        </ul>
        <Button sign={true} text={BtnContants.sign.name} onClick={singBtn} additionalClass={styles.contacts__btn} />
      </div>
    </>
  );
};
