import React, { useEffect, useState } from 'react';

import { StockWin } from 'entites';

export const StockWindow: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [isClose, setIsClose] = useState<boolean>(Boolean(sessionStorage.getItem('isCloseStock')) ?? false);

  const openStock = () => {
    setTimeout(() => {
      setOpen(true);
    }, 5000);
  };

  const closeStock = () => {
    setIsClose(true);
    sessionStorage.setItem('isCloseStock', 'true');
  };

  useEffect(() => {
    openStock();

    return () => {
      setIsClose(false);
      sessionStorage.setItem('isCloseStock', '');
    };
  }, []);

  return (
    <>
      <StockWin open={open} close={isClose} closeStock={closeStock} />
    </>
  );
};
