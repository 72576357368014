import React from 'react';
import { Link } from 'react-router-dom';

import styles from './StockWindow.module.scss';

import image from '../../shared/assets/stock_feedback_window.jpg';

import Button from 'shared/components/Button/Button';
import { BtnContants } from 'shared/constants/constants';
import { dataBase } from 'shared/data/data';
import { disableCopy } from 'shared/model/disableCopy';

const data = dataBase.stocks[0];
const link = dataBase.contacts.links[0].link;

interface IStockWindow {
  open: boolean;
  close: boolean;
  closeStock(): void;
}

export const StockWindow: React.FC<IStockWindow> = ({ open, close, closeStock }: IStockWindow) => {
  return (
    <div
      onMouseDown={disableCopy}
      className={open && !close ? `${styles.stockWindow} ${styles.stockWindow_open}` : styles.stockWindow}
    >
      <img className={styles.stockWindow__img} src={image} alt='feedback' />
      <h2 className={styles.stockWindow__header}>{data.name}</h2>
      <span onClick={closeStock} className={styles.stockWindow__closeBtn}>
        X
      </span>
      <p className={styles.stockWindow__description}>{data.description}</p>
      <Link className='btn-link' to={link} target='_blank'>
        <Button text={BtnContants.details.name} onClick={closeStock} additionalClass={styles.stockWindow__btn} />
      </Link>
    </div>
  );
};
