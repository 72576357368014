import { RouterLinks } from 'shared/constants/routes';

export const getPathName = (location: string): string => {
  let result: string;

  switch (true) {
    case location.includes(RouterLinks.injestServices):
      result = 'inject';
      break;
    case location.includes(RouterLinks.apparatServices):
      result = 'apparat';
      break;
    case location.includes(RouterLinks.otherServices):
      result = 'other';
      break;
    default:
      result = '404';
  }

  return result;
};
