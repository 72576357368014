import React from 'react';

import { ServicesList } from 'widgets';

export const ApparatServiceList: React.FC = () => {
  return (
    <>
      <h1 className='visually-hidden'>Аппаратные методы</h1>
      <ServicesList global={false} apparat={true} inject={false} other={false} />
    </>
  );
};
