import React from 'react';

import { ServicesListItems } from './components/ServicesList';
import styles from './ServicesList.module.scss';
import { IServicesType } from './types/types';

export const ServicesList: React.FC<IServicesType> = ({ global, apparat, inject, other }: IServicesType) => {
  return (
    <section
      className={global ? `${styles.services__section} ${styles.services__section_global}` : styles.services__section}
    >
      <ul className={styles.servicesList}>
        <ServicesListItems global={global} inject={inject} apparat={apparat} other={other} />
      </ul>
    </section>
  );
};
