import React from 'react';

import { Link } from 'react-router-dom';

import styles from './Footer.module.scss';

import { RouterLinks } from 'shared/constants/routes';
import { dataBase } from 'shared/data/data';
import { scrollUp } from 'shared/model/scrollUp';

const data = dataBase.footer;

export const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer__orgInfo}>
        <span>{data.name}</span>
        <span className='d-none'>{data.requisites}</span>
        <span className='d-none'>{data.license}</span>
        <span>{data.years}</span>
      </div>
      <div className={styles.footer__politic}>
        <Link onClick={scrollUp} to={RouterLinks.politic}>
          {data.confidentialPolitic}
        </Link>
      </div>
      <div className={`${styles.footer__production} d-none`}>
        <Link target='_blank' to={`${data.production.link}`}>
          {data.production.name}
        </Link>
      </div>
    </footer>
  );
};
