import React from 'react';

import styles from './ServicesListCard.module.scss';

import { IServices } from 'shared/types/types';

interface IServicesListCard extends IServices {
  additionalClass: string;
}

export const ServicesListCard: React.FC<IServicesListCard> = ({
  id,
  name,
  image,
  additionalClass,
}: IServicesListCard) => {
  return (
    <div id={String(id)} className={`${styles.serviceListCard} ${additionalClass}`}>
      <img className={styles.serviceListCard__img} src={image} alt={name} />
      <h3 className={styles.serviceListCard__header}>{name}</h3>
    </div>
  );
};
