import React from 'react';

import { DevideBlock } from 'entites';

import { BtnContants } from 'shared/constants/constants';
import { dataBase } from 'shared/data/data';
import { consultBtn, singBtn } from 'shared/model/MainFunctionBtns';
import { Contacts, Faq, OrgInfo, Stocks } from 'widgets';

export const MainPage: React.FC = () => {
  return (
    <>
      <h1 className='visually-hidden'>Главная</h1>
      <Stocks />
      <OrgInfo />
      <DevideBlock
        sign={true}
        text={dataBase.global.sign}
        btnText={BtnContants.sign.name}
        btnLink={BtnContants.sign.link}
        btnFn={singBtn}
      />
      <Faq />
      <DevideBlock
        consult={true}
        text={dataBase.global.consult}
        btnText={BtnContants.consultation.name}
        btnLink={BtnContants.consultation.link}
        btnFn={consultBtn}
      />
      <Contacts />
    </>
  );
};
