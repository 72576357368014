import React from 'react';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/autoplay';

import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './SliderStocks.module.scss';

import { StocksCard } from 'entites';
import { dataBase } from 'shared/data/data';

const data = dataBase.stocks;

export const SliderStocks: React.FC = () => {
  return (
    <Swiper
      className={styles.sliderStocks}
      modules={[Autoplay, Navigation]}
      spaceBetween={50}
      slidesPerView={1}
      autoplay={{ delay: 5000 }}
      loop={true}
      navigation
    >
      {data.map((stock) => {
        return (
          <SwiperSlide key={stock.id}>
            {
              <StocksCard
                id={stock.id}
                name={stock.name}
                description={stock.description}
                image={stock.image}
                price={stock.price}
              />
            }
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
