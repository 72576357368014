import React from 'react';

import styles from './OrgInfoAbout.module.scss';

import { dataBase } from 'shared/data/data';
import { disableCopy } from 'shared/model/disableCopy';

const data = dataBase.aboutUs;

export const OrgInfoAbout: React.FC = () => {
  return (
    <div className={styles.orgInfoAbout}>
      <h2 className={styles.orgInfoAbout__header}>{data.header}</h2>
      <div onMouseDown={disableCopy} className={styles.orgInfoAbout__content}>
        <div className={styles.orgInfoAbout__imgWrapper}>
          <img className={styles.orgInfoAbout__img} src={data.image} alt={data.header} />
        </div>
        <div className={styles.orgInfoAbout__textContent}>
          {data.about.map((info) => (
            <p key={info} className={styles.orgInfoAbout__text}>
              {info}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};
