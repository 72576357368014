import React from 'react';

import { ServicesList } from 'widgets';

export const InjectServiceList: React.FC = () => {
  return (
    <>
      <h1 className='visually-hidden'>Инъекционные методы</h1>
      <ServicesList global={false} apparat={false} inject={true} other={false} />
    </>
  );
};
