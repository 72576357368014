import React from 'react';
import { Link } from 'react-router-dom';

import styles from './ConsultBtn.module.scss';

import { dataBase } from 'shared/data/data';
import WhatsappIcon from 'shared/Icons/WhatsappIcon';

const data = dataBase.contacts.links[0];

export const ConsultBtn: React.FC = () => {
  return (
    <Link target='_blank' className={styles.whatsappLink} to={data.link}>
      <WhatsappIcon />
    </Link>
  );
};

export default ConsultBtn;
