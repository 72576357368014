import React from 'react';

import { Service } from 'widgets';

export const ServicePage: React.FC = () => {
  return (
    <>
      <h1 className='visually-hidden'>Страница услуги</h1>
      <Service />
    </>
  );
};
