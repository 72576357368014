import React from 'react';

import styles from './ServicePrice.module.scss';

import { dataBase } from 'shared/data/data';

const data = dataBase.prices;

export const ServicePrice: React.FC = () => {
  return (
    <div className={styles.prices}>
      {data.map((price) => (
        <table className={styles.pricesTable} key={price.serviceName}>
          <thead className={styles.prices__head}>
            <tr className={styles.prices__str}>
              <th colSpan={2} className={styles.prices__header}>
                {price.serviceName}
              </th>
            </tr>
          </thead>
          <tbody className={styles.prices__body}>
            {price.params.map((item, i) => (
              <tr key={item.price + i} className={styles.prices__str}>
                <th className={styles.prices__td}>{item.serviceParams}</th>
                <td className={styles.prices__td}>{item.price}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ))}
    </div>
  );
};
