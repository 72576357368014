import React from 'react';

import { Link } from 'react-router-dom';

import styles from './StocksCard.module.scss';

import Button from 'shared/components/Button/Button';
import { BtnContants } from 'shared/constants/constants';
import { dataBase } from 'shared/data/data';
import { disableCopy } from 'shared/model/disableCopy';
import { IStocks } from 'shared/types/types';

const data = dataBase.contacts.links[0];

export const StocksCard: React.FC<IStocks> = ({ name, description, image, price }: IStocks) => {
  return (
    <div onMouseDown={disableCopy} className={styles.stocksCard}>
      <img className={styles.stocksCard__img} src={image} alt={name} />
      <div className={styles.stocksCardInfo}>
        <h2 className={styles.stocksCardInfo__header}>{name}</h2>
        <p className={styles.stocksCardInfo__about}>{description}</p>
        <span className={styles.stocksCardInfo__price}>{price}</span>
        <Link className='btn-link' to={data.link} target='_blank'>
          <Button text={BtnContants.details.name} onClick={() => {}} additionalClass={styles.stocksCardInfo__btn} />
        </Link>
      </div>
    </div>
  );
};
