import React from 'react';

import styles from './Contacts.module.scss';

import { ContactsFrame } from 'entites';
import { Map } from 'features';
import { dataBase } from 'shared/data/data';

export const Contacts: React.FC = () => {
  return (
    <section id='#contacts' className={styles.contacts__section}>
      <h2>{dataBase.contacts.header}</h2>
      <div className={styles.contacts__content}>
        <Map />
        <ContactsFrame />
      </div>
    </section>
  );
};
