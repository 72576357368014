import React from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  MainPage,
  ErrorPage,
  ServiceList,
  ServicePage,
  SpecialistsPage,
  PoliticPage,
  InjectServiceList,
  ApparatServiceList,
  PricePage,
  OtherServiceList,
} from 'pages';
import { RouterLinks } from 'shared/constants/routes';

const AppRouter: React.FC = () => {
  return (
    <Routes>
      <Route path={`${RouterLinks.main}`} element={<MainPage />} />
      <Route path={`${RouterLinks.services}`} element={<ServiceList />} />
      <Route path={`${RouterLinks.services}${RouterLinks.injestServices}`} element={<InjectServiceList />} />
      <Route path={`${RouterLinks.services}${RouterLinks.apparatServices}`} element={<ApparatServiceList />} />
      <Route path={`${RouterLinks.services}${RouterLinks.otherServices}`} element={<OtherServiceList />} />
      <Route path={`${RouterLinks.services}${RouterLinks.injestServices}/:id`} element={<ServicePage />} />
      <Route path={`${RouterLinks.services}${RouterLinks.apparatServices}/:id`} element={<ServicePage />} />
      <Route path={`${RouterLinks.services}${RouterLinks.otherServices}/:id`} element={<ServicePage />} />
      <Route path={`${RouterLinks.price}`} element={<PricePage />} />
      <Route path={`${RouterLinks.specialists}`} element={<SpecialistsPage />} />
      <Route path={`${RouterLinks.politic}`} element={<PoliticPage />} />
      <Route path='*' element={<ErrorPage />} />
    </Routes>
  );
};

export default AppRouter;
