import React from 'react';

import { PoliticInfo } from 'entites';

export const PoliticPage: React.FC = () => {
  return (
    <>
      <h1 className='visually-hidden'>Политика конфиденциальности</h1>
      <PoliticInfo />
    </>
  );
};
