/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';

import styles from './Map.module.scss';

export const Map: React.FC = () => {
  return (
    <div className={styles.div}>
      <iframe
        className={styles.iframe}
        src='https://yandex.ru/map-widget/v1/?um=constructor%3A64c1c126f99b84fb30647b169c97bd79f9c740b8b379f45348079208dfc4aec5&amp;source=constructor'
        width='960'
        height='597'
      ></iframe>
    </div>
  );
};
