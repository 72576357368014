import React from 'react';

import styles from './InvalidRouteInfo.module.scss';

export const InvalidRouteInfo: React.FC = () => {
  return (
    <div className={styles.invalidRoute}>
      <h1>Страница не найдена &#128532;</h1>
    </div>
  );
};
