import React from 'react';

import styles from './Button.module.scss';

import ConsultIcon from 'shared/Icons/ConsultIcon';
import SignIcon from 'shared/Icons/SignIcon';

interface IButton {
  text: string;
  onClick(): void;
  additionalClass: string;
  sign?: boolean;
  consult?: boolean;
}

const Button: React.FC<IButton> = ({ text, onClick, additionalClass, sign, consult }: IButton) => {
  return (
    <button className={`${styles.button} ${additionalClass}`} onClick={onClick}>
      {sign && (
        <span className={styles.buttonIcon__wrapper}>
          <SignIcon />
        </span>
      )}
      {consult && (
        <span className={styles.buttonIcon__wrapper}>
          <ConsultIcon />
        </span>
      )}
      <span>{text}</span>
    </button>
  );
};

export default Button;
