import React from 'react';

import { CriolipolisPrice } from './components/CriolipolisPrice';

export const CriolipolisesPrice: React.FC = () => {
  return (
    <>
      <CriolipolisPrice />
    </>
  );
};
