import { RouterLinks } from 'shared/constants/routes';

export const navbarList = [
  { name: 'Услуги', link: `${RouterLinks.services}` },
  { name: 'Наши специалисты', link: `${RouterLinks.specialists}` },
  { name: 'Цены', link: `${RouterLinks.price}` },
  { name: 'О нас', link: `${RouterLinks.about}` },
  { name: 'FAQ', link: `${RouterLinks.faq}` },
  { name: 'Контакты', link: `${RouterLinks.contacts}` },
];
