import React from 'react';

import { useLocation } from 'react-router';

import { getId } from './model/getId';
import { getPathName } from './model/getPathName';
import styles from './Service.module.scss';

import { ServiceCard } from 'entites';
import { dataBase } from 'shared/data/data';

export const Service: React.FC = () => {
  const data = dataBase.services;
  const location = useLocation().pathname;
  const path = getPathName(location) as keyof typeof data;
  const id = getId(location) as keyof typeof data;

  const thisData = data[path].filter((service) => service.id === id)[0].types;

  if (!thisData) return null;
  console.log(path);

  return (
    <section className={styles.service__section}>
      {thisData.map((item) => (
        <ServiceCard
          icon={item.icon}
          key={item.id}
          fullDescription={item.fullDescription}
          indications={item.indications}
          contraindications={item.contraindications}
          preparing={item.preparing}
          price={item.price}
          id={item.id}
          name={item.name}
          image={item.image}
        />
      ))}
    </section>
  );
};
