import React from 'react';

import { FaqConstants } from './constants/constants';
import styles from './Faq.module.scss';

import { FaqBlock } from 'features';
import { dataBase } from 'shared/data/data';

const data = dataBase.faq;

export const Faq: React.FC = () => {
  return (
    <section id='#faq' className={styles.faq__section}>
      <h2 className={styles.faq__header}>{FaqConstants.header}</h2>
      <div className={styles.faqBlock__wrapper}>
        {data.map((faq) => (
          <div key={faq.id} className={styles.faqBlock}>
            <FaqBlock
              question={faq.question}
              answer={faq.answer}
              key={faq.id}
              additionalClass={styles.faqToggler__block}
              id={faq.id}
            />
          </div>
        ))}
      </div>
    </section>
  );
};
